import { Route, Switch, useLocation } from 'react-router-dom';
import { useEffect, Suspense, lazy } from 'react';

const HomePage = lazy(() => import('./components/home_page'));
const ContactUs = lazy(() => import('./components/contact_us'));
const AboutUs = lazy(() => import('./components/about_us'));
const Enrollments = lazy(() => import('./components/services/enrollments'));
const PayorContracting = lazy(() => import('./components/services/payor-contracting'));
const Expertise = lazy(() => import('./components/expertise'));
const BCP = lazy(() => import('./components/services/bcp'));
const ROP = lazy(() => import('./components/services/ro'));
const COLMNT = lazy(() => import('./components/services/cm'));
const EBC = lazy(() => import('./components/services/ebc'));
const VM = lazy(() => import('./components/services/vm'));
const CV = lazy(() => import('./components/services/cv'));
const AM = lazy(() => import('./components/services/apm'));
const EVA = lazy(() => import('./components/services/eva'));
const PATIENT = lazy(() => import('./components/services/patient'));
const MC = lazy(() => import('./components/services/mc'));
const CHARGES = lazy(() => import('./components/services/cea'));
const INPATIENT = lazy(() => import('./components/services/inpatient'));
const PP = lazy(() => import('./components/services/pp'));
const CB = lazy(() => import('./components/services/cb'));
const DMR = lazy(() => import('./components/services/dmr'));
const FUP = lazy(() => import('./components/services/fup'));
const BUS = lazy(() => import('./components/services/bus'));
const TECH = lazy(() => import('./components/services/tech'));
const Careers = lazy(() => import('./components/about_us/careers'));
const Privacy = lazy(() => import('./components/footer/privacy'));

function App() {
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path='/'>
            <HomePage />
          </Route>
          <Route exact path='/about-us'>
            <AboutUs />
          </Route>
          <Route exact path='/careers'>
            <Careers />
          </Route>
          <Route exact path='/contact-us'>
            <ContactUs />
          </Route>
          <Route exact path='/enrollments'>
            <Enrollments />
          </Route>
          <Route exact path='/payor-contracting'>
            <PayorContracting />
          </Route>
          <Route exact path='/expertise'>
            <Expertise />
          </Route>
          <Route exact path='/bcp-bia'>
            <BCP />
          </Route>
          <Route exact path='/revenue-optimization'>
            <ROP />
          </Route>
          <Route exact path='/collection-management'>
            <COLMNT />
          </Route>
          <Route exact path='/effective-billing-coding'>
            <EBC />
          </Route>
          <Route exact path='/vendor-management'>
            <VM />
          </Route>
          <Route exact path='/coding-validation'>
            <CV />
          </Route>
          <Route exact path='/patient-appointment-management'>
            <AM />
          </Route>
          <Route exact path='/eligibility-verification-authorization'>
            <EVA />
          </Route>
          <Route exact path='/patient'>
            <PATIENT />
          </Route>
          <Route exact path='/medical-coding'>
            <MC />
          </Route>
          <Route exact path='/charges-entry-creation'>
            <CHARGES />
          </Route>
          <Route exact path='/inpatient-drg-coding'>
            <INPATIENT />
          </Route>
          <Route exact path='/payment-posting'>
            <PP />
          </Route>
          <Route exact path='/credit-balance-overpayment'>
            <CB />
          </Route>
          <Route exact path='/denial-management-rejections'>
            <DMR />
          </Route>
          <Route exact path='/insurance-follow-up-appeals'>
            <FUP />
          </Route>
          <Route exact path='/business-intelligence'>
            <BUS />
          </Route>
          <Route exact path='/technology-recommendations'>
            <TECH />
          </Route>
          <Route exact path='/privacy-policy'>
            <Privacy />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
